import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const languages = ['en', 'fr', 'es', 'ru', 'ar', 'zh']; // Список разрешенных языков
const languagePattern = languages.join('|'); // Создаем паттерн для разрешенных языков

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:locale?',
    name: 'home',
    component: HomeView
  },
  {
    path: '/article/:id/:locale?',
    name: 'article',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue')
  },
  {
    path: '/article/:locale?',
    name: 'article',
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue')
  },
  {
    path: '/nominees/:locale?',
    name: 'nominees',
    component: () => import(/* webpackChunkName: "about" */ '../views/NomineesView.vue')
  },
  {
    path: '/vote/:id/:locale?',
    name: 'vote',
    component: () => import(/* webpackChunkName: "about" */ '../views/VotePage.vue'),
  },
  {
    path: '/vote/:locale?',
    name: 'vote',
    component: () => import(/* webpackChunkName: "about" */ '../views/VotePage.vue')
  },
  {
    path: '/privacy/:locale?',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolitics.vue')
  },
  {
    path: '/terms/:locale?',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsCondition.vue')
  },
  {
    path: '/about/:locale?', // Страница "О нас" с параметром языка
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutSite.vue')
  },
  {
    path: '/winner/:locale?', // Страница "О нас" с параметром языка
    name: 'winner',
    component: () => import(/* webpackChunkName: "about" */ '../views/WinnerView.vue')
  },
  {
    path: '/contact/:locale?',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactPage.vue')
  },
  {
    path: '/thanks/:locale?',
    name: 'thanks',
    component: () => import(/* webpackChunkName: "about" */ '../views/ThanksSite.vue')
  },
  {
    path: '/add/:locale?',
    name: 'add',
    component: () => import(/* webpackChunkName: "about" */ '../views/AddCorporation.vue')
  },
  {
    // Добавляем роут для перенаправления на главную страницу при несуществующем пути
    path: '/:pathMatch(.*)', // Ловим все несуществующие пути
    redirect: '/', // Перенаправляем на главную страницу
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
